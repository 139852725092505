/* eslint-disable react/no-array-index-key */
/* I need to have unique key for each of the letter, but letters are not unique,
so need to use the index too.
*/
import { useState, useEffect } from 'react';

// animation based off of tutorial by DesignCode, found at https://www.youtube.com/watch?v=1rTaNzjGOek

export const TextAnimation = () => {
  const words = ['SHOW OFF', 'TRACK', 'FIND', 'CELEBRATE'];
  const emojis = ['✨', '📈', '🔎', '🎉'];

  const firstWord = (
    <span className="animate-firstWord">
      {`${emojis[0]} ${words[0]} ${emojis[0]}`}
    </span>
  );

  const lastWord = (
    <span className="animate-lastWord">
      {`${emojis[3]} ${words[3]} ${emojis[3]}`}
    </span>
  );

  const [wordIndex, setWordIndex] = useState(-1);
  const [counter, setCounter] = useState(2);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (wordIndex === -1) setWordIndex(1); // first time, go to the second word
      else if (wordIndex < 3) setWordIndex(wordIndex + 1);
      else if (wordIndex === 3 && counter > 0) {
        setWordIndex(0);
        setCounter(counter - 1);
      }
    }, 2500);

    return () => clearTimeout(timer);
  }, [wordIndex, counter]);

  const renderAnimation = () => {
    if (wordIndex < 0) {
      return firstWord;
    }
    if (counter === 0 && wordIndex === 3) {
      return lastWord;
    }
    return (
      <span className="animate-word">
        {`${emojis[wordIndex]} ${words[wordIndex]} ${emojis[wordIndex]}`}
      </span>
    );
  };
  return (
    <span className="whitespace-pre">
      {renderAnimation()}
    </span>
  );
};
